exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-checkout-basket-js": () => import("./../../../src/pages/checkout/basket.js" /* webpackChunkName: "component---src-pages-checkout-basket-js" */),
  "component---src-pages-checkout-booking-configuration-js": () => import("./../../../src/pages/checkout/booking-configuration.js" /* webpackChunkName: "component---src-pages-checkout-booking-configuration-js" */),
  "component---src-pages-checkout-checkoutdotcom-2-payment-failed-dc-js": () => import("./../../../src/pages/checkout/checkoutdotcom2/payment-failed-dc.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-2-payment-failed-dc-js" */),
  "component---src-pages-checkout-checkoutdotcom-2-payment-failed-js": () => import("./../../../src/pages/checkout/checkoutdotcom2/payment-failed.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-2-payment-failed-js" */),
  "component---src-pages-checkout-checkoutdotcom-2-payment-successful-dc-js": () => import("./../../../src/pages/checkout/checkoutdotcom2/payment-successful-dc.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-2-payment-successful-dc-js" */),
  "component---src-pages-checkout-checkoutdotcom-2-payment-successful-js": () => import("./../../../src/pages/checkout/checkoutdotcom2/payment-successful.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-2-payment-successful-js" */),
  "component---src-pages-checkout-checkoutdotcom-3-payment-failed-dc-js": () => import("./../../../src/pages/checkout/checkoutdotcom3/payment-failed-dc.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-3-payment-failed-dc-js" */),
  "component---src-pages-checkout-checkoutdotcom-3-payment-failed-js": () => import("./../../../src/pages/checkout/checkoutdotcom3/payment-failed.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-3-payment-failed-js" */),
  "component---src-pages-checkout-checkoutdotcom-3-payment-successful-dc-js": () => import("./../../../src/pages/checkout/checkoutdotcom3/payment-successful-dc.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-3-payment-successful-dc-js" */),
  "component---src-pages-checkout-checkoutdotcom-3-payment-successful-js": () => import("./../../../src/pages/checkout/checkoutdotcom3/payment-successful.js" /* webpackChunkName: "component---src-pages-checkout-checkoutdotcom-3-payment-successful-js" */),
  "component---src-pages-checkout-confirmation-js": () => import("./../../../src/pages/checkout/confirmation.js" /* webpackChunkName: "component---src-pages-checkout-confirmation-js" */),
  "component---src-pages-checkout-confirmation-order-token-js": () => import("./../../../src/pages/checkout/confirmation/[orderToken].js" /* webpackChunkName: "component---src-pages-checkout-confirmation-order-token-js" */),
  "component---src-pages-checkout-fail-js": () => import("./../../../src/pages/checkout/fail.js" /* webpackChunkName: "component---src-pages-checkout-fail-js" */),
  "component---src-pages-checkout-payment-failed-dc-js": () => import("./../../../src/pages/checkout/payment-failed-dc.js" /* webpackChunkName: "component---src-pages-checkout-payment-failed-dc-js" */),
  "component---src-pages-checkout-payment-failed-js": () => import("./../../../src/pages/checkout/payment-failed.js" /* webpackChunkName: "component---src-pages-checkout-payment-failed-js" */),
  "component---src-pages-checkout-payment-success-dc-js": () => import("./../../../src/pages/checkout/payment-success-dc.js" /* webpackChunkName: "component---src-pages-checkout-payment-success-dc-js" */),
  "component---src-pages-checkout-payment-success-js": () => import("./../../../src/pages/checkout/payment-success.js" /* webpackChunkName: "component---src-pages-checkout-payment-success-js" */),
  "component---src-pages-checkout-voucher-configuration-js": () => import("./../../../src/pages/checkout/voucher-configuration.js" /* webpackChunkName: "component---src-pages-checkout-voucher-configuration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-your-spa-js": () => import("./../../../src/pages/list-your-spa.js" /* webpackChunkName: "component---src-pages-list-your-spa-js" */),
  "component---src-pages-newsletter-confirmation-js": () => import("./../../../src/pages/newsletter-confirmation.js" /* webpackChunkName: "component---src-pages-newsletter-confirmation-js" */),
  "component---src-pages-newsletter-signed-up-js": () => import("./../../../src/pages/newsletter-signed-up.js" /* webpackChunkName: "component---src-pages-newsletter-signed-up-js" */),
  "component---src-pages-spa-short-list-js": () => import("./../../../src/pages/spa-short-list.js" /* webpackChunkName: "component---src-pages-spa-short-list-js" */),
  "component---src-templates-affiliate-page-js": () => import("./../../../src/templates/affiliate-page.js" /* webpackChunkName: "component---src-templates-affiliate-page-js" */),
  "component---src-templates-contact-us-page-js": () => import("./../../../src/templates/contact-us-page.js" /* webpackChunkName: "component---src-templates-contact-us-page-js" */),
  "component---src-templates-corporate-page-js": () => import("./../../../src/templates/corporate-page.js" /* webpackChunkName: "component---src-templates-corporate-page-js" */),
  "component---src-templates-generic-package-listing-page-js": () => import("./../../../src/templates/generic-package-listing-page.js" /* webpackChunkName: "component---src-templates-generic-package-listing-page-js" */),
  "component---src-templates-generic-spa-listing-page-js": () => import("./../../../src/templates/generic-spa-listing-page.js" /* webpackChunkName: "component---src-templates-generic-spa-listing-page-js" */),
  "component---src-templates-health-spas-listing-js": () => import("./../../../src/templates/health-spas-listing.js" /* webpackChunkName: "component---src-templates-health-spas-listing-js" */),
  "component---src-templates-luxury-spas-listing-js": () => import("./../../../src/templates/luxury-spas-listing.js" /* webpackChunkName: "component---src-templates-luxury-spas-listing-js" */),
  "component---src-templates-one-column-page-js": () => import("./../../../src/templates/one-column-page.js" /* webpackChunkName: "component---src-templates-one-column-page-js" */),
  "component---src-templates-outdoor-pools-spas-listing-js": () => import("./../../../src/templates/outdoor-pools-spas-listing.js" /* webpackChunkName: "component---src-templates-outdoor-pools-spas-listing-js" */),
  "component---src-templates-package-category-listing-js": () => import("./../../../src/templates/package-category-listing.js" /* webpackChunkName: "component---src-templates-package-category-listing-js" */),
  "component---src-templates-pamperday-package-js": () => import("./../../../src/templates/pamperday-package.js" /* webpackChunkName: "component---src-templates-pamperday-package-js" */),
  "component---src-templates-plus-size-spas-listing-js": () => import("./../../../src/templates/plus-size-spas-listing.js" /* webpackChunkName: "component---src-templates-plus-size-spas-listing-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simple-page.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-spa-break-location-listing-js": () => import("./../../../src/templates/spa-break-location-listing.js" /* webpackChunkName: "component---src-templates-spa-break-location-listing-js" */),
  "component---src-templates-spa-breaks-page-js": () => import("./../../../src/templates/spa-breaks-page.js" /* webpackChunkName: "component---src-templates-spa-breaks-page-js" */),
  "component---src-templates-spa-day-location-listing-js": () => import("./../../../src/templates/spa-day-location-listing.js" /* webpackChunkName: "component---src-templates-spa-day-location-listing-js" */),
  "component---src-templates-spa-days-page-js": () => import("./../../../src/templates/spa-days-page.js" /* webpackChunkName: "component---src-templates-spa-days-page-js" */),
  "component---src-templates-spa-deal-location-listing-js": () => import("./../../../src/templates/spa-deal-location-listing.js" /* webpackChunkName: "component---src-templates-spa-deal-location-listing-js" */),
  "component---src-templates-spa-deals-listing-js": () => import("./../../../src/templates/spa-deals-listing.js" /* webpackChunkName: "component---src-templates-spa-deals-listing-js" */),
  "component---src-templates-spa-group-listing-js": () => import("./../../../src/templates/spa-group-listing.js" /* webpackChunkName: "component---src-templates-spa-group-listing-js" */),
  "component---src-templates-spa-group-package-js": () => import("./../../../src/templates/spa-group-package.js" /* webpackChunkName: "component---src-templates-spa-group-package-js" */),
  "component---src-templates-spa-hotels-page-js": () => import("./../../../src/templates/spa-hotels-page.js" /* webpackChunkName: "component---src-templates-spa-hotels-page-js" */),
  "component---src-templates-spa-insider-article-page-js": () => import("./../../../src/templates/spa-insider-article-page.js" /* webpackChunkName: "component---src-templates-spa-insider-article-page-js" */),
  "component---src-templates-spa-insider-category-js": () => import("./../../../src/templates/spa-insider-category.js" /* webpackChunkName: "component---src-templates-spa-insider-category-js" */),
  "component---src-templates-spa-insider-hub-page-js": () => import("./../../../src/templates/spa-insider-hub-page.js" /* webpackChunkName: "component---src-templates-spa-insider-hub-page-js" */),
  "component---src-templates-spa-js": () => import("./../../../src/templates/spa.js" /* webpackChunkName: "component---src-templates-spa-js" */),
  "component---src-templates-spa-location-listing-js": () => import("./../../../src/templates/spa-location-listing.js" /* webpackChunkName: "component---src-templates-spa-location-listing-js" */),
  "component---src-templates-spa-package-js": () => import("./../../../src/templates/spa-package.js" /* webpackChunkName: "component---src-templates-spa-package-js" */),
  "component---src-templates-spa-review-js": () => import("./../../../src/templates/spa-review.js" /* webpackChunkName: "component---src-templates-spa-review-js" */),
  "component---src-templates-spa-voucher-activate-page-js": () => import("./../../../src/templates/spa-voucher-activate-page.js" /* webpackChunkName: "component---src-templates-spa-voucher-activate-page-js" */),
  "component---src-templates-spa-voucher-page-js": () => import("./../../../src/templates/spa-voucher-page.js" /* webpackChunkName: "component---src-templates-spa-voucher-page-js" */),
  "component---src-templates-spas-listing-js": () => import("./../../../src/templates/spas-listing.js" /* webpackChunkName: "component---src-templates-spas-listing-js" */)
}

